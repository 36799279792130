import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SystemActions } from '../actions';
import { CustomizationService } from '../services/customization.service';

@Injectable()
export class SystemEffects {

  loadManagedCustomization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemActions.loadManagedCustomization),
      switchMap(() => {
        return this.customizationService.listManagedCustomizations().pipe(
          mergeMap((response: any) => {
            if (response?.customizations?.length > 0) {
              return this.customizationService.getManagedCustomization(response.customizations[0].id).pipe(
                mergeMap((res: any) => {
                  return of(SystemActions.loadManagedCustomizationSuccess({ customization: res.customization }));
                }),
                catchError((error: HttpErrorResponse) =>
                  of(SystemActions.loadManagedCustomizationFailure({ error: error.message }))
                )
              );
            } else {
              return of(SystemActions.loadManagedCustomizationFailure({ error: 'No Managed Customization' }))
            }

          }), catchError((error: HttpErrorResponse) =>
            of(SystemActions.loadManagedCustomizationFailure({ error: error.message }))
          ));
      }))
  );

  loadManagedBackgrounds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemActions.loadManagedBackgrounds),
      switchMap(() => {
        return this.customizationService.listManagedBackgrounds().pipe(
          map((response: any) => {
            return SystemActions.loadManagedBackgroundsSuccess({ backgrounds: response.backgrounds });
          }),
          catchError((error: HttpErrorResponse) =>
            of(SystemActions.loadManagedCustomizationFailure({ error: error.message }))
          )
        );
      }))
  );

  loadManagedLogos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemActions.loadManagedLogos),
      switchMap(() => {
        return this.customizationService.listManagedLogos().pipe(
          map((response: any) => {
            return SystemActions.loadManagedLogosSuccess({ logos: response.logos });
          }),
          catchError((error: HttpErrorResponse) =>
            of(SystemActions.loadManagedLogosFailure({ error: error.message }))
          )
        );
      }))
  );

  constructor(
    private actions$: Actions,
    private readonly customizationService: CustomizationService,
  ) { }

}
