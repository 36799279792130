import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import * as fromRoot from '@app/reducers';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { buffer, filter, first, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentActions } from './core/actions';
import { TokenService } from './core/services/token.service';
import { splashTransition } from './shared/animations/splash-transitions';
import { getCurrentLangFromPath, getProtocolHostnameAndPort } from './shared/helpers/location';
import { Device } from './shared/models/device';
import { Identity } from './shared/models/identity';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { REQUEST } from '../express.tokens';
import { Request } from 'express';
import { errors, Iam } from '@smash-sdk/iam/10-2019';
import { config, HttpResponse, Region } from '@smash-sdk/core/dist';
import packageJson from '../../package.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        splashTransition
    ]
})
export class AppComponent implements OnInit {

    identity$: Observable<Identity>;
    identityUpdating$: Observable<boolean>;
    device$: Observable<Device>;
    route$: Observable<any>;

    loading = true;

    subscriptions: Subscription[] = [];
    identitySync: Identity;
    toFullfill: any[] = [];

    constructor(
        private readonly store$: Store<fromRoot.State>,
        private tokenService: TokenService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        @Optional() @Inject(REQUEST) private req: Request,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.device$ = this.store$.select(fromRoot.getDevice);
        this.route$ = this.store$.select(fromRoot.getRoute);
        this.identityUpdating$ = this.store$.select(fromRoot.getActiveIdentityUpdating);
        this.identity$ = this.store$.select(fromRoot.getActiveIdentity);

        this.identity$.subscribe(identity => this.identitySync = identity);
        // Scitylana
        this.router.events.pipe(
            filter(event => {
                if (event instanceof Scroll) {
                    this.toFullfill.push(event);
                }
                let isTokenExpired = true;
                if (!!this.identitySync?.token?.token) {
                    isTokenExpired = this.tokenService.isTokenExpired(this.identitySync.token.token);
                }
                return !!this.identitySync && !isTokenExpired;
            })
        ).subscribe(() => {
            if (this.toFullfill.length) {
                this.parseRoutingEvent(this.toFullfill);
            }
        });
    }

    refreshTokenMethod(httpResponse: HttpResponse<any>, retries?: number) {
        return new Promise(async (resolve, reject) => {
            try {
                if (retries !== undefined && retries < 4) {
                    const { region, refreshToken } = await this.identity$.pipe(first(identity => !!identity)).toPromise();
                    const response = await new Iam({ region: region as Region }).renewToken({ refreshToken: refreshToken.token });
                    resolve(response.identity.token.token);
                } else {
                    resolve(null);
                }
            } catch (error: unknown) {
                if (error instanceof errors.RenewTokenError.BadGatewayError
                    || error instanceof errors.RenewTokenError.GatewayTimeoutError
                    || error instanceof errors.RenewTokenError.NetworkError
                    || error instanceof errors.RenewTokenError.UnknownError
                    || error instanceof errors.RenewTokenError.InternalServerError) {
                    const { token } = await this.identity$.pipe(first(identity => !!identity)).toPromise();
                    resolve(token.token);
                } else if (error instanceof errors.RenewTokenError.IdentityDisabledError
                    || error instanceof errors.RenewTokenError.InvalidParameterError
                    || error instanceof errors.RenewTokenError.InvalidRefreshTokenError
                    || error instanceof errors.RenewTokenError.NotAllowedError //TODO FIX ME this error change in the next verison of iam
                    || error instanceof errors.RenewTokenError.PasswordRevokedError
                    || error instanceof errors.RenewTokenError.NotFoundError) {
                    this.router.navigateByUrl('/signout');
                    resolve(null);
                } else {
                    this.router.navigateByUrl('/signout');
                    reject(error);
                }
            }
        });
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            // this.browserSide = true;
            config.setRefreshTokenMethod(this.refreshTokenMethod.bind(this));
        } else {
            // update tag html lang according to current language path
            const pathName = isPlatformBrowser(this.platformId) ? window.location.pathname : this.req.originalUrl;
            const currentLang = getCurrentLangFromPath(pathName);
            this.document.documentElement.lang = currentLang;
        }
    }

    parseRoutingEvent(events: any[]) {
        if (isPlatformBrowser(this.platformId)) {
            const filteredEvents = events.filter(event => (event instanceof Scroll && !event.routerEvent.url.match(/^\/redirect/)));
            filteredEvents.map(event => {
                const urlTarget = event.routerEvent.url;
                this.store$.dispatch(EnvironmentActions.CreateEventScitylana({
                    event: {
                        event: 'pageView',
                        url: getProtocolHostnameAndPort() + urlTarget,
                        referer: document.referrer,
                        date: new Date().toISOString(),
                        clientName: environment.clientName,
                        clientVersion: packageJson.version,
                    }
                }));
            }, this);
            this.toFullfill = [];
        }
    }
}
