import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DomainService } from '@app/core/services/domain.service';
import * as fromRoot from '@app/reducers';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Constant } from 'src/constant';
import { environment } from '../environments/environment';
import { AccountActions, EnvironmentActions } from './core/actions';
import { CookiesService } from './core/services/cookies.service';
import { Identity } from './shared/models/identity';
import { LocalStorageService } from './core/services/local-storage.service';
import { isPlatformBrowser } from '@angular/common';
import { config } from '@smash-sdk/core';
@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    private readonly store: Store<fromRoot.State>,
    private readonly domainService: DomainService,
    private readonly cookiesService: CookiesService,
    private readonly localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  init(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      console.log('Boot app version: ' + environment.version);
      console.log('Date : ' + moment().format('LLL'));
      // Checking if browser is supported, i.e not IE
      try {
        await this.checkBrowserCompatibility();
        await this.loadDeviceInfos(this.store);
        await this.cleanLocalStorage();
        await Promise.all([
          this.loadLanguage(this.store),
          this.handleMediaQueries(this.store),
          this.handleIdentityInCookies(this.store),
        ]);
        if (isPlatformBrowser(this.platformId)) {
          if (window.location.pathname === Constant.redirection.serviceUnavailable) {
            window.location.pathname = '/';
          }
        }
        resolve();
      } catch (error) {
        if (isPlatformBrowser(this.platformId) && window.location.pathname === Constant.redirection.unsupported) {
          resolve();
        } else {
          if (isPlatformBrowser(this.platformId) && window.location.pathname !== '/') {
            this.localStorageService.setItem('urlAim', window.location.href);
          } else {
            this.localStorageService.removeItem('urlAim');
          }
          if (isPlatformBrowser(this.platformId)) {
            window.location.pathname = Constant.redirection.unsupported;
          }
          reject();
        }
      }
    });
  }

  loadLanguage(store: Store<fromRoot.State>): Promise<void> {
    return new Promise((resolve) => {
      const loadedLanguage$ = new Subject();
      store.dispatch(EnvironmentActions.SetupLanguage());
      store.select(fromRoot.getSelectedLanguage).pipe(
        takeUntil(loadedLanguage$)
      ).subscribe(language => {
        if (language) {
          console.log('Language: ' + language);
          moment.locale(language);
          loadedLanguage$.next(true);
          resolve();
        }
      });
    });
  }

  handleMediaQueries(store: Store<fromRoot.State>): Promise<void> {
    return new Promise((resolve) => {
      if (isPlatformBrowser(this.platformId)) {
        const url = this.domainService.detectDomainUrl();
        const urlClass = new URL(url);
        const params = new URLSearchParams(urlClass.search);
        if (params.get('auth')) {
          const authParams = params.get('auth');
          const SSOLoginOrigin = this.cookiesService.getCookie('SSOLoginOrigin');
          if (SSOLoginOrigin) {
            this.cookiesService.removeCookie('SSOLoginOrigin');
            window.location.href = SSOLoginOrigin + '?auth=' + authParams;
          }
          this.setupSsoSession(authParams, store);
        }
        if (params.get(Constant.encodedInfosRedirectionKey)) {
          const redirectionParams = params.get(Constant.encodedInfosRedirectionKey);
          this.reloadPreviousLocalStorage(redirectionParams, store);
        }
        if (params.get('duration')) {
          this.store.dispatch(EnvironmentActions.SetDurationChoice({ durationChoice: params.get('duration') }));
          urlClass.searchParams.delete('duration');
        }
        this.setupPrepareState(params); // FIX ME
        if (params.get(Constant.smashQueryParams.themeId)) {
          this.localStorageService.setItem(Constant.smashQueryParams.themeId, params.get(Constant.smashQueryParams.themeId));
        }
        if (params.get(Constant.smashQueryParams.themeLanguage)) {
          this.localStorageService.setItem(Constant.smashQueryParams.themeLanguage, params.get(Constant.smashQueryParams.themeLanguage));
        }
        if (params.get(Constant.smashQueryParams.themeCountryCode)) {
          this.localStorageService.setItem(Constant.smashQueryParams.themeCountryCode, params.get(Constant.smashQueryParams.themeCountryCode));
        }
      }
      resolve();
    });
  }

  handleIdentityInCookies(store: Store<fromRoot.State>): Promise<void> {
    return new Promise((resolve) => {
      if (!isPlatformBrowser(this.platformId)) {
        resolve();
      } else {
        store.select(fromRoot.getActiveIdentity).pipe(
          take(1)
        ).subscribe((identity: Identity) => {
          const cookieIdentity = this.cookiesService.getCookie('identity') as Identity;
          const identityExist = this.cookiesService.cookieExists('identity');
          if (identityExist && cookieIdentity) {
            config.setToken(cookieIdentity.token.token);
            this.store.dispatch(AccountActions.UpdateActiveIdentitySuccess({ identity: cookieIdentity }));
          } else if (identity && !cookieIdentity) {
            this.cookiesService.setCookie('identity', identity);
            config.setToken(cookieIdentity.token.token);
            this.store.dispatch(AccountActions.UpdateActiveIdentitySuccess({ identity }));
          }
          resolve();
        });
      }
    });
  }

  loadDeviceInfos(store: Store<fromRoot.State>): Promise<void> {
    return new Promise((resolve) => {
      const loadedDeviceInfos$ = new Subject();
      store.dispatch(EnvironmentActions.loadDevice());
      store.select(fromRoot.getDevice).pipe(
        takeUntil(loadedDeviceInfos$)
      ).subscribe((device: any) => {
        if (device) {
          const {
            userAgent = Constant.Unknown,
            os = Constant.Unknown,
            device: deviceName = Constant.Unknown,
            os_version = Constant.Unknown,
            browser = Constant.Unknown,
            browser_version = Constant.Unknown
          } = device.deviceInfo;

          console.log('Device: ' + deviceName + ' (name) | ' + os + ' (os) | ' + os_version + ' (version)');
          console.log('Environment: Production : ' + environment.production);
          console.log('Browser: ' + browser + ' (name) | ' + browser_version + ' (version)');
          console.log('UserAgent: ' + userAgent);
          loadedDeviceInfos$.next(true);
          resolve();
        }
      });
    });
  }

  // FIX ME IE is crashing before checking compatibility on IE
  checkBrowserCompatibility(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (isPlatformBrowser(this.platformId) && window.navigator.userAgent.match(/(MSIE|Trident)/)) {
        reject();
      }
      resolve();
    });
  }

  setupSsoSession(params, store: Store<fromRoot.State>): Promise<void> {
    return new Promise((resolve, reject) => {
      const informations = this.decodeParams(params);
      store.dispatch(AccountActions.SetupSsoSession({ informations }));
      const setupSsoSessionSuccess$ = new Subject();
      store.select(fromRoot.getSetupSsoSessionSuccess).pipe(
        takeUntil(setupSsoSessionSuccess$)
      ).subscribe((setupSsoSessionSuceess: any) => {
        if (setupSsoSessionSuceess) {
          resolve();
        }
      });
    });
  }

  /* TO REMOVE Temp clean keys from localstorage*/
  cleanLocalStorage(): Promise<void> {
    return new Promise(resolve => {
      // TO REMOVE We don't sync anything anymore from 'uploader' localStorageKey, but we still need it to migrate old userContacts in uploader.prepare
      const protectedKeys = ['account', 'uploader', 'environment', 'team', 'transfersSentTableConfig', 'lastTransferParameters', 'prefilledTransferParameters'];

      this.localStorageService.listAllKeys().forEach(key => {
        if (!protectedKeys.find(protectedKey => key === protectedKey)) {
          this.localStorageService.removeItem(key);
        }
      });
      resolve();
    });
  }

  reloadPreviousLocalStorage(params: any, store: Store<fromRoot.State>) {
    const decodedParams = this.decodeParams(params);
    const identity = decodedParams.activeIdentity;
    const teamSelected = decodedParams.teamSelected;
    const selectedLanguage = decodedParams.selectedLanguage;
    store.dispatch(AccountActions.selectActiveIdentity({ identity }));
    config.setToken(identity.token.token);
    store.dispatch(AccountActions.selectActiveTeam({ team: teamSelected }));
    store.dispatch(EnvironmentActions.SelectLanguage({ language: selectedLanguage }));
  }

  /* This method handles feature to prefill upload parameters with query string.
    The key 'type' is now replaced by 'uploadFormMode' (value 'Email' | 'Link' case-sensitive).
    Users that use this feature are used to the 'type' key, so please don't rename it for now.
    Example : https://fromsmash.com?sender=teste2e@fromsmash.com&name=testName&receivers=receiver1@test.com,receiver2@test.com&type=Email&description=descriptionTest&title=titleTest
  */
  setupPrepareState(params: any): void {
    this.localStorageService.removeItem('prefilledTransferParameters');
    const uploadFormMode = params.get('type');
    const sender = params.get('sender');
    const name = params.get('name');
    const receivers = params.get('receivers')?.split(',');
    const description = params.get('description');
    const title = params.get('title');
    if (uploadFormMode || sender || name || receivers || description || title) {
      this.localStorageService.setItem('prefilledTransferParameters', JSON.stringify({
        sender,
        name,
        receivers,
        uploadFormMode,
        description,
        title,
      }));
    }
  }

  private decodeParams(encodedParams: string) {
    const decodedParams = decodeURIComponent(escape(atob(decodeURIComponent(encodedParams))));
    return JSON.parse(decodedParams);
  }
}

