import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { HomeModule } from './home/home.module';

export const routes: Routes = [
    {
        path: appRoutesNames.EMPTY_URL,
        loadChildren: () => import('@app/client/client.module').then(m => m.ClientModule), // THis notation enable lazy loaded module .. 
    },
    {
        path: '',
        outlet: 'home',
        loadChildren: () => HomeModule,
    },
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })], // This value is required for server-side rendering to work.
    exports: [RouterModule]
})
export class AppRoutingModule { }
