import { Component, Inject, Optional, PLATFORM_ID, makeStateKey, StateKey, TransferState, OnInit, OnDestroy } from '@angular/core';
import { EnvironmentActions } from '@app/core/actions';
import { CookiesService } from '@app/core/services/cookies.service';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromRoot from '@app/reducers';
import { CGUConsent } from '@app/shared/models/cguConsent';
import { Observable, Subscription } from 'rxjs';

import { isPlatformServer } from '@angular/common';
import { REQUEST } from '../../express.tokens';
import { Request } from 'express';

const CGU_SHOW: StateKey<boolean> = makeStateKey('cguShow');
const COOKIE_SHOW: StateKey<boolean> = makeStateKey('cookieShow');

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

    cguConsent$: Observable<CGUConsent>;
    isCookiesConsentSetByUser$: Observable<boolean>;
    cguDisplayed: boolean;
    cookieDisplayed: boolean;
    subscriptions: Subscription[] = [];

    constructor(
        private state: TransferState,
        private readonly store$: Store<fromRoot.State>,
        private cookiesService: CookiesService,
        @Inject(PLATFORM_ID) private platformId: any,
        @Optional() @Inject(REQUEST) private req: Request,
    ) {
        this.cguDisplayed = this.state.get<boolean>(CGU_SHOW, false);
        this.cookieDisplayed = this.state.get<boolean>(COOKIE_SHOW, false);

        this.cguConsent$ = this.store$.select(fromRoot.getCGUConsent);
        this.isCookiesConsentSetByUser$ = this.store$.select(fromRoot.getIsCookiesConsentSetByUser);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    ngOnInit() {
        if (isPlatformServer(this.platformId)) {
            const cguCookiesFromReq = this.req.cookies['_scgu'] ? JSON.parse(this.req.cookies['_scgu']) : null;
            const cookieConsentFromReq = this.req.cookies['_sucs'] ? JSON.parse(this.req.cookies['_sucs']) : false;
            // Cgu consent
            if (!cguCookiesFromReq || cguCookiesFromReq?.cguLastVersion < environment.cgu.version) {
                this.cguDisplayed = true;
                this.state.set<boolean>(CGU_SHOW, true);
            } else {
                this.store$.dispatch(EnvironmentActions.AcceptCGU()); // maybe useless
                this.state.set<boolean>(CGU_SHOW, false);
            }
            // Cookie consent
            if (this.cguDisplayed && !cookieConsentFromReq) {
                this.cookieDisplayed = false;
                this.state.set<boolean>(COOKIE_SHOW, false);
            } else if (!this.cguDisplayed && !cookieConsentFromReq) {
                this.cookieDisplayed = true;
                this.state.set<boolean>(COOKIE_SHOW, true);
            }
        } else {
            this.cguDisplayed = this.state.get<boolean>(CGU_SHOW, false); // follow what the server tells you
            this.cookieDisplayed = this.state.get<boolean>(COOKIE_SHOW, false);
            if (!this.cguDisplayed) { // if cguDisplayed not shown it means user has accepted the cgu
                this.store$.dispatch(EnvironmentActions.AcceptCGU());
            }
            this.subscriptions.push(
                this.cguConsent$.subscribe(cguLocalStorage => {
                    if (!cguLocalStorage.cguLastConsentUpdated
                        || (cguLocalStorage.cguLastConsentUpdated && cguLocalStorage.cguLastVersion < environment.cgu.version)) {
                        this.cguDisplayed = true;
                    } else {
                        this.cguDisplayed = false;
                    }
                })
            );
            this.store$.dispatch(EnvironmentActions.LoadCookiesConsentConfiguration());
            if (!this.cookiesService.getCookie('userConsentSet')) {
                this.cookieDisplayed = true;
            }
        }

    }

    onManageCookies(event) {
        this.cookieDisplayed = false;
    }
}
