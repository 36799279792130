import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as PaymentActions from '@app/core/actions/payment.actions';
import { PaymentStripeService } from '@app/core/services/payment-stripe.service';
import * as fromRoot from '@app/reducers';
import { getProtocolHostnameAndPort } from '@app/shared/helpers/location';
import { retryOn500 } from '@app/shared/operators/retryOn500';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, retryWhen, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Constant } from 'src/constant';
import { Billing } from '@smash-sdk/billing/01-2024';
import InitiatePaypalPaymentError from '@smash-sdk/billing/01-2024/types/InitiatePaypalPayment/InitiatePaypalPaymentError';

@Injectable()
export class PaymentEffects {

  processPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processPayment),
      mergeMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store$.select(fromRoot.getPaymentType)
          )
        )
      ),
      switchMap(([action, paymentType]: any) => {
        switch (paymentType) {
          case Constant.paymentType.CARD:
            return [PaymentActions.processStripePayment()];
          case Constant.paymentType.PAYPAL:
            return [PaymentActions.processPaypalPayment()];
          default:
            break;
        }
      })
    )
  );

  processStripePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processStripePayment),
      mergeMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store$.select(fromRoot.getCardToken)
          )
        )
      ),
      switchMap(([action, cardToken]: any) => {
        const billingSdk = new Billing();
        return from(billingSdk.initiateStripePayment()).pipe(
          mergeMap((resPaymentIntent) => {
            const { paymentIntent } = resPaymentIntent;
            const dataToPay = {
              secret: paymentIntent.clientSecret,
              token: cardToken.id
            };
            return this.paymentStripeService.stripeHandleCardPayment(dataToPay).then((resStripe) => {
              if (resStripe.error) {
                return PaymentActions.processStripePaymentFailure({ error: resStripe.error });
              } else {
                const { paymentIntent } = resStripe;
                return PaymentActions.processStripePaymentCheck({ paymentIntent: paymentIntent.id });
              }
            });
          }),
          catchError((error: any) => {
            return of(PaymentActions.processStripePaymentFailure({ error: error.message }));
          }
          )
        );
      }),
      catchError((error: any) => {
        return of(PaymentActions.processStripePaymentFailure({ error: error.message }));
      })
    )
  );

  processStripePaymentCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processStripePaymentCheck),
      concatMap((action) => {
        const billingSdk = new Billing();
        return from(billingSdk.getSubscription()).pipe(
          concatMap(({ subscription }) => {
            return from(billingSdk.getInformations()).pipe(
              concatMap(({ informations }) => {
                return from(billingSdk.executeStripePayment({ paymentIntentId: action.paymentIntent })).pipe(
                  retryOn500(),
                  mergeMap(({ payment }) => {
                    if (payment.status === Constant.paymentStatus.SUCCESS) {
                      return [PaymentActions.processStripePaymentSuccess({ response: { payment, subscription, plan: subscription.plan, informations } })];
                    } else {
                      return [{ type: 'noop' }];
                    }
                  }),
                  catchError((error: any) => {
                    return of(PaymentActions.processStripePaymentFailure({ error: error.message }));
                  }),
                );
              }));
          }));
      }),
      catchError((error: any) => {
        return of(PaymentActions.processStripePaymentFailure({ error: error.message }));
      })
    )
  );

  processStripePaymentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processStripePaymentSuccess),
      map(action => action),
      tap(() => {
        this.store$.dispatch(PaymentActions.processPaymentSuccess());
      })
    ),
    { dispatch: false }
  );

  // paypal
  processPaypalPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.processPaypalPayment),
      concatMap((action) => {
        const billingSdk = new Billing();
        return from(billingSdk.initiatePaypalPayment())
          .pipe(
            map(({ approvalUrl }) => {
              // wait for user approval
              return PaymentActions.paypalWaitUserAgreement({ approvalUrl });
            }),
            retryWhen(error => {
              let retries = 1;
              return error.pipe(
                take(3),
                map((err: HttpErrorResponse) => {
                  if (retries === 3) { // last call throw error anyway
                    throw error;
                  }

                  if (!(error instanceof InitiatePaypalPaymentError.InternalServerError)) { // if something else than 5xx throw an error...
                    throw error;
                  }
                  retries++;
                })
              );
            }),
            catchError((error: any) => {
              return of(PaymentActions.processPaypalPaymentFailure({ error: error.message }));
            }),
          );
      })
    )
  );

  executePaypal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.executePaypal),
      map(action => action.token),
      concatMap((token) => {
        const billingSdk = new Billing();
        return from(billingSdk.getSubscription()).pipe(
          concatMap(({ subscription }) => {
            return from(billingSdk.getInformations()).pipe(
              concatMap(({ informations }) => {
                return from(billingSdk.executePaypalPayment({ token })).pipe(
                  retryOn500(),
                  mergeMap(({ payment }: any) => {
                    if (payment.status === Constant.paymentStatus.SUCCESS) {
                      return [PaymentActions.processPaypalPaymentSuccess({ response: { payment, subscription, plan: subscription.plan, informations } })];
                    } else {
                      return [{ type: 'noop' }];
                    }
                  }),
                  catchError((error: any) => {
                    return of(PaymentActions.processPaypalPaymentFailure({ error: error.message }));
                  }),
                );
              }));
          }));
      }),
      catchError((error: any) => {
        return of(PaymentActions.processPaypalPaymentFailure({ error: error.message }));
      })
    )
  );

  cancelPaypal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.cancelPaypal),
      switchMap(() => {
        const billingSdk = new Billing();
        return from(billingSdk.getSubscription()).pipe(
          concatMap(({ subscription }) => {
            return from(billingSdk.getPlanGroup({ planId: subscription.plan.id })).pipe(
              concatMap(({ group }: any) => {
                const baseUrl = getProtocolHostnameAndPort();
                window.location.href = baseUrl + '/signup/plan/' + encodeURIComponent(group.id);
                return [{ 'type': 'noop' }];
              })
            );
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromRoot.State>,
    private readonly paymentStripeService: PaymentStripeService,
  ) { }

}
