import { Injectable } from '@angular/core';
import * as fromViewer from '@app/client/viewer/reducers/viewer.reducer';
import * as fromRoot from '@app/reducers';
import { selectTheme } from '@app/shared/helpers/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Theme as ThemeSdk } from '@smash-sdk/theme/04-2024';
import { exhaustMap, withLatestFrom } from 'rxjs/operators';
import { Language } from 'smash-type';
import { Constant } from 'src/constant';
import { ThemeActions } from '../actions';
import { GoogleTagManagerService } from '../lib/google-tag-manager/angular-google-tag-manager.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class ThemeEffects {

  loadTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ThemeActions.LoadTheme),
      withLatestFrom(
        this.store$.select(fromRoot.getCountryCode),
        this.store$.select(fromRoot.getSelectedLanguage),
        this.store$.select(fromRoot.getDevice),
      ),
      exhaustMap(async ([action, countryCode, selectedLanguage, device]) => {
        const themeSdk = new ThemeSdk();
        const { themes } = await themeSdk.listManagedThemesPublic();

        const localStorageCountryCode = this.localStorageService.getItem(Constant.smashQueryParams.themeCountryCode);
        const localStorageLanguage = this.localStorageService.getItem(Constant.smashQueryParams.themeLanguage)?.toLowerCase();
        const localStorageThemeId = this.localStorageService.getItem(Constant.smashQueryParams.themeId);

        const targetLocation = localStorageCountryCode || countryCode;
        const supportedLanguages = Object.keys(Constant.supportedLanguages);
        const targetLanguage = supportedLanguages.includes(localStorageLanguage) ? localStorageLanguage : selectedLanguage;

        const theme = selectTheme(themes, {
          device: device.isMobile ? 'Mobile' : 'Desktop',
          language: targetLanguage as Language,
          location: targetLocation,
          target: action.target,
          themeId: localStorageThemeId,
        });

        if (theme) {
          this.googleTagManagerService.pushTag({
            theme_id: theme.id,
            theme_name: theme.name,
            theme_teasing_background_type: theme.teasing?.background?.type,
            theme_teasing_background_url: theme.teasing?.background?.url,
            theme_teasing_headger_logo_url: theme.teasing?.headerLogo,
            theme_target: theme.target,
          });
        }

        return ThemeActions.LoadThemeSuccess({ theme });
      })
    )
  );

  constructor(
    private readonly store$: Store<fromRoot.State & fromViewer.State>,
    private googleTagManagerService: GoogleTagManagerService,
    private localStorageService: LocalStorageService,
    private actions$: Actions,
  ) { }

}
