import { Background } from '@app/shared/models/background';
import { Customization } from '@app/shared/models/customization';
import { Logo } from '@app/shared/models/logo';
import { createAction, props } from '@ngrx/store';
import { GetDomainCustomizationOutput } from '@smash-sdk/domain/01-2024';

// Team
export const loadCustomizationReceiver = createAction(
  '[Customization] Load Customization Receiver',
);

export const loadCustomizationReceiverSuccess = createAction(
  '[Customization] Load Customization Receiver Success',
  props<{ customization: Customization }>()
);

export const loadCustomizationReceiverFailure = createAction(
  '[Customization] Load Customization Receiver Failure',
  props<{ error: any }>()
);

export const loadTeamBackgrounds = createAction(
  '[Customization] Load Team Backgrounds',
);

export const loadTeamBackgroundsSuccess = createAction(
  '[Customization] Load Team Backgrounds Success',
  props<{ backgrounds: Background[] }>()
);

export const loadTeamBackgroundsFailure = createAction(
  '[Customization] Load Team Backgrounds Failure',
  props<{ error: any }>()
);

export const loadTeamLogos = createAction(
  '[Customization] Load Team Logos',
);

export const loadTeamLogosSuccess = createAction(
  '[Customization] Load Team Logos Success',
  props<{ logos: Logo[] }>()
);

export const loadTeamLogosFailure = createAction(
  '[Customization] Load Team Logos Failure',
  props<{ error: any }>()
);

// Edition
export const selectBackground = createAction(
  '[Customization] Select background',
  props<{ background: GetDomainCustomizationOutput['customization']['background'] }>()
);

export const selectLogo = createAction(
  '[Customization] Select logo',
  props<{ logo: GetDomainCustomizationOutput['customization']['logo'] }>()
);

export const addTeamLogo = createAction(
  '[Customization] Add Team Logo',
  props<{ image?: any[], url?: string }>()
);

export const addTeamLogoSuccess = createAction(
  '[Customization] Add Team Logo Success',
  props<{ logo: Logo }>()
);

export const addTeamLogoFailure = createAction(
  '[Customization] Add Team Logo Failure',
  props<{ error: any }>()
);

export const addTeamBackgroundImage = createAction(
  '[Customization] Add Team Background Image',
  props<{ image?: any[], url?: string }>()
);

export const addTeamBackgroundImageSuccess = createAction(
  '[Customization] Add Team Background Image Success',
  props<{ background: GetDomainCustomizationOutput['customization']['background'] }>()
);

export const addTeamBackgroundImageFailure = createAction(
  '[Customization] Add Team Background Image Failure',
  props<{ error: any }>()
);

export const addTeamBackgroundVideo = createAction(
  '[Customization] Add Team Background Video',
  props<{ url: string }>()
);

export const addTeamBackgroundVideoSuccess = createAction(
  '[Customization] Add Team Background Video Success',
  props<{ background: Background }>()
);

export const addTeamBackgroundVideoFailure = createAction(
  '[Customization] Add Team Background Video Failure',
  props<{ error: any }>()
);

export const deleteTeamLogo = createAction(
  '[Customization] Delete Team Logo',
  props<{ id: string }>()
);

export const deleteTeamLogoSuccess = createAction(
  '[Customization] Delete Team Logo Success',
);

export const deleteTeamLogoFailure = createAction(
  '[Customization] Delete Team Logo Failure',
  props<{ error: any }>()
);

export const deleteTeamBackground = createAction(
  '[Customization] Delete Team background',
  props<{ id: string }>()
);

export const deleteTeamBackgroundSuccess = createAction(
  '[Customization] Delete Team background Success',
);

export const deleteTeamBackgroundFailure = createAction(
  '[Customization] Delete Team background Failure',
  props<{ error: any }>()
);

export const updateTeamCustomization = createAction(
  '[Customization] Update Team Customization',
);

export const updateCustomizationSuccess = createAction(
  '[Customization] Update Customization Success',
);

export const updateTeamCustomizationFailure = createAction(
  '[Customization] Update Team Customization Failure',
  props<{ error: any }>()
);

export const addTeamBackgroundImageToCreate = createAction(
  '[Customization] Add Team Background Image To Create',
  props<{ image: any }>()
);

export const addTeamBackgroundImageToCreateSuccess = createAction(
  '[Customization] Add Team Background Image To Create Success',
  props<{ background: any }>()
);

export const addTeamBackgroundVideoToCreate = createAction(
  '[Customization] Add Team Background Video To Create',
  props<{ video: any }>()
);

export const addTeamBackgroundVideoToCreateSuccess = createAction(
  '[Customization] Add Team Background Video To Create Success',
  props<{ background: any }>()
);

export const addTeamBackgroundToDelete = createAction(
  '[Customization] Add Team Background To Delete',
  props<{ background: Background }>()
);

export const addTeamLogoToCreate = createAction(
  '[Customization] Add Team Logo To Create',
  props<{ logo: any }>()
);

export const addTeamLogoToCreateSuccess = createAction(
  '[Customization] Add Team Logo To Create Success',
  props<{ logo: any }>()
);

export const addTeamLogoToDelete = createAction(
  '[Customization] Add Team Logo To Delete',
  props<{ logo: Logo }>()
);


