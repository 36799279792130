const regexpYoutube = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/;
const regexpVimeo = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;

export function getVideoIdFromUrl(url: string): any {
    let match: any;
    let video: any;
    if (url.match(regexpYoutube)) {
        match = url.match(regexpYoutube);
        video = { url, id: match[1], type: 'Youtube' };
    } else if (url.match(regexpVimeo)) {
        match = url.match(regexpVimeo);
        video = { url, id: match[1], type: 'Vimeo' };
    }
    return video;
}

export function splitUrl(url: string): any {
    if (url) {
        return url.split('/');
    }
    return null;
}

export function addOrUpdateUrlParam(url: string, name: string, value: any): string {
    var regex = new RegExp("[&\\?]" + name + "=");
    if (regex.test(url)) {
        regex = new RegExp("([&?])" + name + "=(.+)");
        url = url.replace(regex, "$1" + name + "=" + value);
    }
    else {
        if (url?.indexOf("?") > -1) {
            url = url + "&" + name + "=" + value;

        } else {
            url = url + "?" + name + "=" + value;
        }
    }
    return url;
}