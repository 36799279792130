<div class="cgu-main-container" >
    <div class="cgu-sub-container">
        <div class="cgu-content" *ngIf="template">
            <ng-container *ngIf="template==='MAIN'">
                <div class="title" [innerHtml]="'CGU_OVERLAY_TITLE_UPLOADER' | translate | safeHtml"></div>
                <div class="description" [innerHtml]="'CGU_OVERLAY_DESCRIPTION_UPLOADER_NOTSUPPORTED' | translate:{link_about: linkAboutTemplate, link_api: linkAPITemplate} | safeHtml"></div>

            </ng-container>
            <ng-container *ngIf="template==='VIEWER'">
                <div class="title" >{{'CGU_OVERLAY_TITLE_VIEWER' | translate}}</div>
                <div class="description" [innerHtml]="'CGU_OVERLAY_DESCRIPTION_VIEWER' | translate:{link_about: linkAboutTemplate, link_api: linkAPITemplate} | safeHtml"></div>
            </ng-container>

            <div class="cta-container">
                <button class="btn-v3 red cta-link" [disabled]='viewLoading' (click)='acceptCGU()' >
                    <div [ngClass]="{'show': viewLoading}" class="loading-spinner-white spinner-button"></div>
                    <div>{{ 'CGU_OVERLAY_BUTTON_ACCEPT' | translate }}</div>
                </button>
                <a target="_blank" href="{{ 'CGU_MOBILE_LEARN_ABOUT_SMASH_URL_LINK_ABOUT' | translate }}" class="btn-v3 dark-filaire cta-link" >{{'CGU_MOBILE_LEARN_ABOUT_SMASH_LABEL_LINK_ABOUT' | translate}}</a>
                <!-- Warning, id for GTM click are handled in .ts -->
            </div>
        </div>
    </div>
</div>