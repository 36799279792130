
import { Injectable, Inject, PLATFORM_ID, Optional, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { getCurrentLangFromPath, getDomainWithoutSubDomain } from '@app/shared/helpers/location';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { REQUEST } from '../../../express.tokens';
import { Request } from 'express';
import { BytesPipe } from '@app/shared/pipes/bytes.pipe';
import { Constant } from 'src/constant';

@Injectable({
    providedIn: 'root'
})
export class SEOService {

    constructor(
        private titleService: Title,
        private metaTagService: Meta,
        private readonly translateService: TranslateService,
        private bytesPipe: BytesPipe,
        @Inject(DOCUMENT) private dom,
        @Optional() @Inject(REQUEST) private req: Request,
        @Inject(PLATFORM_ID) private platformId: any
    ) {

    }

    setFaqRichResults(renderer2: Renderer2) {
        let script = renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `
            {
                "@context": "https://schema.org",                
                "@type": "FAQPage",
                "name":  "${this.translateService.instant('HOMEPAGE_ACCORDEON_FAQ_NAME').replace(/"/g, "\\\"")}",
                "mainEntity": [{
                    "@type": "Question",
                    "name": "${this.translateService.instant('HOMEPAGE_ACCORDEON_SUMMARY_1').replace(/"/g, "\\\"")}",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " ${this.translateService.instant('HOMEPAGE_ACCORDEON_RESPONSE_1').replace(/"/g, "\\\"")}"
                    }
                },{
                    "@type": "Question",
                    "name": "${this.translateService.instant('HOMEPAGE_ACCORDEON_SUMMARY_2').replace(/"/g, "\\\"")}",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " ${this.translateService.instant('HOMEPAGE_ACCORDEON_RESPONSE_2').replace(/"/g, "\\\"")}"
                    }
                },{
                    "@type": "Question",
                    "name": "${this.translateService.instant('HOMEPAGE_ACCORDEON_SUMMARY_3').replace(/"/g, "\\\"")}",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " ${this.translateService.instant('HOMEPAGE_ACCORDEON_RESPONSE_3').replace(/"/g, "\\\"")}"
                    }
                },{
                    "@type": "Question",
                    "name": "${this.translateService.instant('HOMEPAGE_ACCORDEON_SUMMARY_4').replace(/"/g, "\\\"")}",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " ${this.translateService.instant('HOMEPAGE_ACCORDEON_RESPONSE_4').replace(/"/g, "\\\"")}"
                    }
                },{
                    "@type": "Question",
                    "name": "${this.translateService.instant('HOMEPAGE_ACCORDEON_SUMMARY_5').replace(/"/g, "\\\"")}",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " ${this.translateService.instant('HOMEPAGE_ACCORDEON_RESPONSE_5').replace(/"/g, "\\\"")}"
                    }
                },{
                    "@type": "Question",
                    "name": "${this.translateService.instant('HOMEPAGE_ACCORDEON_SUMMARY_6').replace(/"/g, "\\\"")}",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " ${this.translateService.instant('HOMEPAGE_ACCORDEON_RESPONSE_6').replace(/"/g, "\\\"")}"
                    }
                },{
                    "@type": "Question",
                    "name": "${this.translateService.instant('HOMEPAGE_ACCORDEON_SUMMARY_7').replace(/"/g, "\\\"")}",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": " ${this.translateService.instant('HOMEPAGE_ACCORDEON_RESPONSE_7').replace(/"/g, "\\\"")}"
                    }
                }]                
            }
        `;
        renderer2.appendChild(this.dom.head, script);
    }

    updateCanonicalUrl(url: string) {
        let arr = Array.from(this.dom.head.children);
        let canonical: any = arr.find((e: any) => e.rel == "canonical");
        canonical.setAttribute('href', this.getProtocol() + url)

    }

    updateHreflang() {
        let arr = Array.from(this.dom.head.children);
        let linkEltArr = arr.filter((e: any) => e.rel == "alternate");
        const isPathNameLocalized = ['fr', 'en', 'de', 'es', 'it', 'pt'].indexOf(this.req.originalUrl.split('/')[1]) > -1 ? 2 : 1;
        linkEltArr.map((e: any) => {
            if (['x-default', 'en'].indexOf(e.hreflang) > -1) {
                e.setAttribute('href', this.getHostnameWithProtocol() + '/' + this.req.originalUrl.split('/').splice(isPathNameLocalized).join('/'));
            } else {
                const pathWithoutLang = '/' + this.req.originalUrl.split('/').splice(isPathNameLocalized).join('/');
                const resolvePathname = pathWithoutLang.length > 1 ? pathWithoutLang : '';
                e.setAttribute('href', this.getHostnameWithProtocol() + '/' + e.hreflang + resolvePathname);
            }
        })
    }

    removeHreflang() {
        let arr = Array.from(this.dom.head.children);
        let linkEltArr = arr.filter((e: any) => e.rel == "alternate");
        linkEltArr.map((e: any) => {
            this.dom.head.removeChild(e)
        });
        this.metaTagService.addTag({ name: 'robots', content: 'noindex' });
    }

    setMetasSignup() {
        if (isPlatformServer(this.platformId)) {
            const url = this.req.originalUrl;
            const lang = this.translateService.currentLang;
            this.updateHreflang();
            this.titleService.setTitle(this.translateService.instant('SEO_TITLE_SIGNUP'));
            this.updateCanonicalUrl(getDomainWithoutSubDomain(this.getHostname()) + url);
            this.metaTagService.updateTag(
                { name: 'description', content: this.translateService.instant('SEO_DESCRIPTION_SIGNUP') }
            );
            this.metaTagService.updateTag({ property: 'og:url', content: this.getHostnameWithProtocol() + '/' + lang + this.resolvePath(url) });
            this.metaTagService.updateTag({ property: 'og:title', content: this.translateService.instant('SEO_TITLE_SIGNUP') });
            this.metaTagService.updateTag({ property: 'og:description', content: this.translateService.instant('SEO_DESCRIPTION_SIGNUP') });
            if (this.getHostname() !== getDomainWithoutSubDomain(this.getHostname())) {
                this.removeHreflang();
            }
        }
    }

    setMetasSignin() {
        if (isPlatformServer(this.platformId)) {
            const url = this.req.originalUrl;
            const lang = this.translateService.currentLang;
            this.updateHreflang();
            this.titleService.setTitle(this.translateService.instant('SEO_TITLE_SIGNIN'));
            this.updateCanonicalUrl(getDomainWithoutSubDomain(this.getHostname()) + url);
            this.metaTagService.updateTag(
                { name: 'description', content: this.translateService.instant('SEO_DESCRIPTION_SIGNIN') }
            );
            this.metaTagService.updateTag({ property: 'og:url', content: this.getHostnameWithProtocol() + '/' + lang + this.resolvePath(url) });
            this.metaTagService.updateTag({ property: 'og:title', content: this.translateService.instant('SEO_TITLE_SIGNIN') });
            this.metaTagService.updateTag({ property: 'og:description', content: this.translateService.instant('SEO_DESCRIPTION_SIGNIN') });
            if (this.getHostname() !== getDomainWithoutSubDomain(this.getHostname())) {
                this.removeHreflang();
            }
        }
    }

    setMetasLanding() {
        if (isPlatformServer(this.platformId)) {
            this.updateHreflang();
            this.setLandingTitle();
            this.metaTagService.updateTag({ property: 'og:url', content: this.getHostnameWithProtocol() + '/' + this.resolveLangFromPath() });
            this.metaTagService.updateTag({ name: 'description', content: this.translateService.instant('SEO_DESCRIPTION_LANDING') });
            this.metaTagService.updateTag({ property: 'og:site_name', content: 'Smash' });
            this.metaTagService.updateTag({ property: 'og:title', content: this.translateService.instant('SEO_TITLE_LANDING') });
            this.metaTagService.updateTag({ property: 'og:description', content: this.translateService.instant('SEO_DESCRIPTION_LANDING') });

            this.updateCanonicalUrl(getDomainWithoutSubDomain(this.getHostname()) + '/' + this.resolveLangFromPath());
            if (this.getHostname() !== getDomainWithoutSubDomain(this.getHostname())) {
                this.removeHreflang();
            }
        }
    }

    setLandingTitle() {
        this.titleService.setTitle(this.translateService.instant('SEO_TITLE_LANDING'));
    }

    setMetasViewer(transfer: any) {
        if (isPlatformServer(this.platformId)) {

            const transferLang = transfer.language ? transfer.language : 'en'; // mobile App transfers dont have language defined
            this.translateService.use(transferLang);
            this.titleService.setTitle('Smash');
            this.metaTagService.updateTag({ property: 'og:site_name', content: 'Smash' });

            const title = transfer.password ?
                this.translateService.instant('SEO_TITLE_VIEWER_PASSWORD')
                : this.translateService.instant('SEO_TITLE_VIEWER', { count: transfer.filesNumber, size: this.bytesPipe.transform(transfer.size, 2) });

            const isExpired = transfer.status !== 'Uploaded' ? `(${this.translateService.instant(Constant.transferStatusTranslations[transfer.status])})` : '';
            this.metaTagService.updateTag({ property: 'og:title', content: `${title} ${isExpired}` });

            const description = transfer.password ?
                this.translateService.instant('SEO_DESCRIPTION_VIEWER_PASSWORD')
                : this.translateService.instant('SEO_DESCRIPTION_VIEWER', { count: transfer.filesNumber });
            this.metaTagService.updateTag({ name: 'description', content: description });
            this.metaTagService.updateTag({ property: 'og:description', content: description });
            this.updateCanonicalUrl(getDomainWithoutSubDomain(this.getHostname()) + '/' + (transferLang === 'en' ? '' : transferLang));

            if (this.getHostname() !== getDomainWithoutSubDomain(this.getHostname())) {
                this.removeHreflang();
            }

        }
    }

    resolvePath(url: string) {
        const isPathNameLocalized = ['fr', 'en', 'de', 'es', 'it', 'pt'].indexOf(url.split('/')[1]) > -1 ? 2 : 1;
        const pathWithoutLang = '/' + url.split('/').splice(isPathNameLocalized).join('/');
        return pathWithoutLang.length > 1 ? pathWithoutLang : '';
    }

    resolveLangFromPath() {
        const url = this.req.originalUrl;
        const currentLang = getCurrentLangFromPath(url);
        return currentLang === 'en' ? '' : currentLang;
    }

    getHostname() {
        return this.req.headers['x-forwarded-host'] ? this.req.headers['x-forwarded-host'] as string : this.req.hostname; // this is for local development
    }

    getProtocol() {
        return this.req.headers['x-forwarded-proto'] ? `${this.req.headers['x-forwarded-proto']}://` as string : 'http://'; // // this is for local development
    }

    getHostnameWithProtocol() {
        const protocol = this.getProtocol();
        const hostname = this.getHostname()
        return `${protocol}${hostname}`;
    }
}