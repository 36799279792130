import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  debug(msg: string) {
    console.log('Debug:', msg);
  }

  log(msg: string) {
    console.log(msg);
  }

  error(msg: string, stack?: string) {
    console.error('Error:', msg, stack);
  }
}
