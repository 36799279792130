import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app.routing-module';
import { CguComponent } from './cgu/cgu.component';
import { CookieComponent } from './cookie/cookie.component';
import { ROOT_REDUCERS } from './reducers';
import { I18nModule } from './shared/modules/i18n/i18n.module';
import { AppComponent } from '@app/app.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';
import { TeamsEffects } from './client/account/admin/teams/effects/teams.effects';
import { AccountEffects, DomainEffects, EnvironmentEffects } from './core/effects';
import { ThemeEffects } from './core/effects/theme.effects';
import { SystemEffects } from './core/effects/system.effects';
import { TeamEffects } from './core/effects/team.effects';
import { EffectsModule } from '@ngrx/effects';
import { CoreModule } from './core/core.module';
import { environment } from 'src/environments/environment';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { provideClientHydration } from '@angular/platform-browser';
import { StatusCodeResponseService } from './core/services/status-code-response.service';


const metaReducers: Array<MetaReducer<any, any>> = [];
const localStorageSyncReducer = (
  reducer: ActionReducer<any>
): ActionReducer<any> => {
  return localStorageSync({
    keys: [
      { account: ['plan', 'country', 'userContacts'] },
      { team: ['teamSelected'] },
      { environment: ['selectedLanguage', 'region', 'onBoarding'] },
    ],
    rehydrate: true,
    restoreDates: false
  })(reducer);
};

if (typeof window !== "undefined") {
  metaReducers.push(localStorageSyncReducer);
}


export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CookieComponent,
    CguComponent,
    HomeComponent,
  ],
  imports: [
    I18nModule,
    CommonModule,
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot({ // this is used for storing router in store and show title in account section
      routerState: RouterState.Minimal,
    }),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    EffectsModule.forRoot([
      AccountEffects,
      ThemeEffects,
      EnvironmentEffects,
      DomainEffects,
      TeamEffects,
      TeamsEffects,
      SystemEffects
    ]),
    SharedModule,
    CoreModule,
  ],
  exports: [AppComponent, HomeComponent],
  providers: [
    provideClientHydration(),
    StatusCodeResponseService,
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
    { provide: 'googleTagManagerAuth', useValue: environment.googleTagManagerAuth },
    { provide: 'googleTagManagerPreview', useValue: environment.googleTagManagerPreview },
  ],
})

export class AppModule {
  constructor() { }
}
